<template>
  <div id="MapTable">
    <div class="title">
      <div style="width: 18px"></div>
      <p>{{ table.title }}</p>
      <!-- <img :src="deleteImg" alt="" @click="onDelete" /> -->
    </div>
    <div class="nav-title">
      {{ JZMC }}{{ baseNumber }}个基站数据{{ BJMC }}{{ company }}
    </div>
    <div class="table-entity">
      <div class="table-shell">
        <a-table
          class="full-table"
          :loading="loading"
          :columns="table.columns"
          bordered
          size="small"
          :row-key="
            (r, i) => {
              return i.toString();
            }
          "
          :data-source="data"
          :pagination="false"
        >
        </a-table>
      </div>
      <div style="display: flex; padding: 10px 20px">
        <div style="flex: 1"></div>
        <a-pagination
          :page-size="pageSize"
          v-model="current"
          :total="total"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { http } from "@/utils/Http.js";

let table1 = {
  title: "当前超标的企业数据详情",
  data: [],
  type: 2,
  columns: [
    {
      title: "序号",
      width: 60,
      align: "center",
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "企业名称",
      ellipsis: true,
      width: 200,
      dataIndex: "EnterpriseMC",
      align: "center",
    },
    {
      title: "基站名称",
      ellipsis: true,
      width: 160,
      dataIndex: "station_name",
      align: "center",
    },
    {
      title: "监测因子",
      dataIndex: "ItemName",
      align: "center",
    },
    {
      title: "超标时长",
      dataIndex: "SC",
      align: "center",
    },
    {
      title: "超标时段",
      width: 200,
      dataIndex: "SD",
      align: "center",
    },
    {
      title: "排放标准",
      dataIndex: "PCBZ",
      align: "center",
    },
    {
      title: "超标最大值",
      dataIndex: "YCZ",
      align: "center",
    },
  ],
};

let table2 = {
  title: "当前异常的企业数据详情",
  data: [],
  type: 1,
  columns: [
    {
      title: "序号",
      width: 60,
      align: "center",
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "企业名称",
      ellipsis: true,
      width: 200,
      dataIndex: "EnterpriseMC",
      align: "center",
    },
    {
      title: "基站名称",
      width: 160,
      ellipsis: true,
      dataIndex: "station_name",
      align: "center",
    },
    {
      title: "监测因子",
      dataIndex: "ItemName",
      align: "center",
    },
    {
      title: "异常时长",
      dataIndex: "SC",
      align: "center",
    },
    {
      title: "异常时段",
      dataIndex: "SD",
      align: "center",
    },
    {
      title: "异常类型",
      dataIndex: "YCLX",
      align: "center",
      customRender: (text, record, index) => {
        // (1:最大值 2：最小值)
        let name = "";

        if (text == 1) {
          name = "最大值";
        } else if (text == 2) {
          name = "最小值";
        }
        return name;
      },
    },
    {
      title: "异常数值",
      dataIndex: "YCZ",
      align: "center",
    },
  ],
};

let table3 = {
  title: "当前缺失的企业详情",
  data: [],
  type: 3,
  columns: [
    {
      title: "序号",
      width: 60,
      align: "center",
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "企业名称",
      ellipsis: true,
      width: 200,
      dataIndex: "EnterpriseMC",
      align: "center",
    },
    {
      title: "基站名称",
      width: 160,
      ellipsis: true,
      dataIndex: "station_name",
      align: "center",
    },
    {
      title: "传输率",
      dataIndex: "CSL",
      align: "center",
    },
    {
      title: "缺失时段",
      dataIndex: "SD",
      align: "center",
    },
    {
      title: "备注",
      dataIndex: "BZ",
      align: "center",
    },
  ],
};

let table4 = {
  title: "当前停运的企业详情",
  data: [],
  type: 4,
  columns: [
    {
      title: "序号",
      width: 60,
      align: "center",
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "企业名称",
      ellipsis: true,
      width: 200,
      dataIndex: "EnterpriseMC",
      align: "center",
    },
    {
      title: "基站名称",
      width: 160,
      ellipsis: true,
      dataIndex: "station_name",
      align: "center",
    },
    {
      title: "停运时段",
      dataIndex: "TYSD",
      align: "center",
    },
    {
      title: "停运报告",
      width: 120,
      dataIndex: "TYBB",
      align: "center",
    },
    {
      title: "停运原因",
      dataIndex: "TYYY",
      align: "center",
    },
  ],
};

export default {
  props: {
    reportData: Object,
  },
  components: {},
  data() {
    return {
      JZMC: "",
      BJMC: "",
      company: "",
      baseNumber: 0,

      deleteImg: require("../../assets/img/pics/042.png"),

      data: [],
      table: {},
      loading: false,

      //分页
      current: 1,
      pageSize: 10,
      total: 0,

      url: null,
      params: null,
    };
  },
  created() {
    this.getParams(this.reportData);
  },
  watch: {
    reportData(value) {
      console.log(value);
      this.current = 1;
      this.data = [];
      this.getParams(value);
    },
  },
  methods: {
    //分页
    onChange(page, pageSize) {
      this.current = page;
      console.log(this.reportData);

      this.params.PAGESIZE = this.pageSize;
      this.params.PAGENUMBER = this.current;

      console.log(this.params);
      this.getData(this.url, this.params);
    },

    getParams(reportData) {
      var me = this;
      var reportData = this.reportData;
      console.log(reportData);
      // reportData.type; //报警类型
      // reportData.TOTALDAY; //统计时间
      // reportData.LX; //基站类型

      var url = "";
      var params = [];

      // reportData.type  --报警类型
      // 2、超标
      // 3、异常
      // 4、缺失
      // 5、停运

      // reportData.LX  --基站类型

      this.company = "（mg/m³）";
      if (reportData.LX == 1) {
        this.JZMC = "废气";
      } else if (reportData.LX == 2) {
        this.JZMC = "废水";
        this.company = "（mg/L）";
      } else if (reportData.LX == 3) {
        this.JZMC = "扬尘";
      } else if (reportData.LX == 4) {
        this.JZMC = "VOCS";
      }

      if (reportData.type == 2) {
        this.BJMC = "超标";
        url = "/data/q30/HJJC_DAYTOTAL_ABNORMAL_LIST.aspx";
        params = {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: reportData.TOTALDAY,
          BJLX: 2,
          STATIONTYPE: reportData.LX,
          PAGESIZE: this.pageSize,
          PAGENUMBER: this.current,
        };
        this.table = table1;
      } else if (reportData.type == 3) {
        this.BJMC = "异常";
        url = "/data/q30/HJJC_DAYTOTAL_ABNORMAL_LIST.aspx";
        params = {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: reportData.TOTALDAY,
          BJLX: 1,
          STATIONTYPE: reportData.LX,
          PAGESIZE: this.pageSize,
          PAGENUMBER: this.current,
        };
        this.table = table2;
      } else if (reportData.type == 4) {
        this.BJMC = "缺失";
        url = "/data/q30/HJJC_DAYTOTAL_LACK_LIST.aspx";
        params = {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: reportData.TOTALDAY,
          STATIONTYPE: reportData.LX,
          PAGESIZE: this.pageSize,
          PAGENUMBER: this.current,
        };
        this.table = table3;
      } else if (reportData.type == 5) {
        this.BJMC = "停运";
        url = "/data/q30/HJJC_DAYTOTAL_OUTAGE_LIST.aspx";
        params = {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: reportData.TOTALDAY,
          STATIONTYPE: reportData.LX,
          PAGESIZE: this.pageSize,
          PAGENUMBER: this.current,
        };
        this.table = table4;
      }

      // if (LBBH == 1) {
      //   //超标
      //   url = "/data/q30/HJJC_DAYTOTAL_NEW_ABNORMAL_LIST.aspx";
      //   params = {
      //     CKM: me.$store.state.loggedIn.CKM,
      //     TOTALDAY: reportData.today,
      //     BJLX: 2,
      //     PAGESIZE: me.pageSize,
      //     PAGENUMBER: me.current,
      //   };
      //   this.table = table1;
      // } else if (LBBH == 2) {
      //   //异常
      //   url = "/data/q30/HJJC_DAYTOTAL_NEW_ABNORMAL_LIST.aspx";
      //   params = {
      //     CKM: me.$store.state.loggedIn.CKM,
      //     TOTALDAY: reportData.today,
      //     BJLX: 1,
      //     PAGESIZE: me.pageSize,
      //     PAGENUMBER: me.current,
      //   };
      //   this.table = table2;
      // } else if (LBBH == 3) {
      //   url = "/data/q30/HJJC_DAYTOTAL_NEW_LACK_LIST.aspx";
      //   params = {
      //     CKM: me.$store.state.loggedIn.CKM,
      //     TOTALDAY: reportData.today,
      //     PAGESIZE: me.pageSize,
      //     PAGENUMBER: me.current,
      //   };
      //   this.table = table3;
      // } else if (LBBH == 4) {
      //   url = "/data/q30/HJJC_STATIONLIST_OUTAGE_LIST.aspx";
      //   params = {
      //     CKM: me.$store.state.loggedIn.CKM,
      //     SFBH: "",
      //     SBH: "",
      //     XBH: "",
      //     JZMC: "",
      //     SXZM: "",
      //     KSSJ: reportData.today,
      //     JSSJ: reportData.today,
      //     start: (me.current - 1) * me.pageSize,
      //     limit: me.pageSize,
      //   };
      //   this.table = table4;
      // }

      this.url = url;
      this.params = params;

      this.getData(url, params);
    },

    getData(url, params) {
      var me = this;
      this.loading = true;
      // console.log(me.$store.state.loggedIn);
      http({
        url: url,
        data: params,
        success(obj) {
          console.log(obj);

          me.loading = false;

          me.data = obj.Data;

          if (obj.Data1 && obj.Data1.length > 0) {
            me.baseNumber = obj.Data1[0].JZS;
          }

          if (obj.Data2 && obj.Data2.length > 0) {
            me.total = obj.Data2[0].ROWSCOUNT;
          }
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },
  },
};
</script>
<style lang="scss">
.table-shell .ant-table-row:nth-child(2n) {
  background: rgba(97, 97, 97, 0.04);
}
</style>
<style lang="scss" scoped>
#MapTable {
  min-height: 332px;
  padding: 20px 30px 10px;
  background: rgba(255, 255, 255, 0.9);

  .title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      text-align: center;
      flex: 1;
      margin: 0;
    }

    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .nav-title {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  .table-entity {
    .table-shell {
      // height: 3.94rem;
      &::v-deep .ant-table-wrapper {
        .ant-table-content {
          border: 0;
        }
        .ant-table {
          border: 0;
        }

        table {
          border: 0;

          .ant-table-thead {
            tr {
              border: 0;
              th {
                border: 0;
                font-size: 12px;
                padding: 11px 5px;
              }
            }
          }
          tr {
            border: 0;
            td {
              border: 0;
              padding: 7px 5px;
            }
          }
        }
      }
    }
  }
}
</style>
