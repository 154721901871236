<template>
  <div id="map" ref="mapShell">
    <baidu-map
      class="bm-view"
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      :double-click-zoom="true"
      @ready="handler"
    >
      <!-- markers 展现区 -->
      <bml-marker-clusterer :averageCenter="true" :styles="customStyles">
        <bm-marker
          v-for="(item, index) in markers"
          :key="index"
          :position="{ lng: item.station_long, lat: item.station_lat }"
          :icon="{
            url: '/tinified/' + item.url,
            size: {
              width: 60,
              height: 60,
            },
          }"
          @click="onClickStation(index)"
        >
          <bm-label
            v-if="item.error_number > 0"
            :content="item.error_number"
            :labelStyle="item.labelStyle"
            :offset="{ width: 35, height: 3 }"
          />
        </bm-marker>
      </bml-marker-clusterer>

      <!-- 右下角图例 -->
      <bm-control anchor="BMAP_ANCHOR_BOTTOM_RIGHT">
        <map-legend></map-legend>
      </bm-control>
    </baidu-map>
    <a-drawer
      :placement="placement"
      :closable="false"
      :visible="visible"
      class="map-drawer"
      width="569"
      :mask="false"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
    >
      <map-base
        @close="onClose"
        @shellCustomRow="onCustomRow"
        @shellCustomRow1="onCustomRow1"
        @mapSetData="onMapSetData"
      ></map-base>
    </a-drawer>
    <div class="img-btn" v-if="!visible" @click="onToggle"></div>

    <!-- 表格弹框 -->
    <a-modal
      v-model="mapTableDialog"
      centered
      :footer="null"
      :width="modalWidth"
      wrapClassName="map-dialog-wrapper"
    >
      <map-table v-bind:reportData="reportData"></map-table>
    </a-modal>

    <!-- 基站详情弹框 -->
    <a-modal
      v-model="mapBaseDialog"
      centered
      :footer="null"
      width="739px"
      wrapClassName="map-dialog-wrapper"
    >
      <map-details v-bind:baseData="baseData"></map-details>
    </a-modal>
  </div>
</template>
<script>
import { BmlMarkerClusterer } from "vue-baidu-map";
import MapLegend from "./MapLegend.vue";
import MapBase from "./MapBase.vue";
import MapTable from "./MapTable.vue";
import MapDetails from "./MapDetails.vue";
import { http } from "@/utils/Http.js";
import dayjs from "dayjs";

export default {
  name: "AppMap",
  components: { BmlMarkerClusterer, MapLegend, MapBase, MapTable, MapDetails },
  data() {
    return {
      //地图参数
      zoom: 15,
      center: { lng: 114.24, lat: 35.7858 },
      // 地图聚合绑定的 style 数组
      customStyles: [
        {
          url: "/tinified/jh.png",
          size: { width: 60, height: 60 },
          opt_anchor: [40, 35],
          textColor: "#ff0000",
          opt_textSize: 12,
        },
      ],

      markers: [],

      labelStyleYc: {
        color: "#fff",
        fontSize: "12px",
        background: "#800080",
        borderRadius: "50%",
        width: "22px",
        height: "22px",
        lineHeight: "18px",
        textAlign: "center",
      },

      labelStyleCb: {
        color: "#fff",
        fontSize: "12px",
        background: "#FF0000",
        borderRadius: "50%",
        width: "22px",
        height: "22px",
        lineHeight: "18px",
        textAlign: "center",
      },

      labelStyleYj: {
        color: "#fff",
        fontSize: "12px",
        background: "#FF8000",
        borderRadius: "50%",
        width: "22px",
        height: "22px",
        lineHeight: "18px",
        textAlign: "center",
      },

      //地图左侧筛选
      visible: true,
      placement: "left",

      // 表格弹框
      modalWidth: 1130,
      mapTableDialog: false,
      reportData: {},

      //基站详情弹框
      mapBaseDialog: false,
      baseData: null,

      params: {
        MC: "",
        area_num: "",
        station_type: "",
      },
    };
  },

  created() {
    var me = this;

    // 改变窗口大小时重新设置 rem
    window.onresize = function () {
      me.setRem();
    };

    // 进入时就重新设置
    window.onload = function () {
      me.setRem();
    };

    if (localStorage.getItem("loggedIn")) {
      var loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      this.$store.commit("setLoggedIn", loggedIn);
    } else {
      this.$router.push("/login");
    }
  },

  mounted() {},

  methods: {
    setRem() {
      const baseSize = 100;
      // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 1920;
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 2) + "px";
    },

    handler({ BMap, map }) {
      //   console.log(BMap, map);
      let me = this;
      me.BMap = BMap;
      me.map = map;

      me.getAjax();
    },

    /**
     * 请求接口，获取基站列表
     */
    getAjax() {
      let me = this;
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        MC: this.params.MC,
        area_num: this.params.area_num,
        station_type: this.params.station_type,
        station_time: dayjs(new Date()).format("YYYY-MM-DD"),
        PAGESIZE: "",
        PAGENUMBER: "",
      };
      http({
        url: "/data/q30/HJJC_MAP_LIST.aspx",
        data: params,
        success(obj) {
          console.log(obj);

          if (obj.Data && obj.Data.length > 0) {
            me.setCentre(obj.Data[0]); // 设置中心店

            let markers = me.getMarkers(obj.Data);

            // console.log(markers);
            me.markers = markers;
          }
        },
        failure(obj) {},
        me,
      });
    },

    /**
     * 设置地图中心点
     */
    setCentre({ station_long, station_lat }) {
      let me = this;
      console.log(station_long);
      console.log(station_lat);
      me.center.lng = station_long;
      me.center.lat = station_lat;
    },

    /**
     * 获取marker数组
     */
    getMarkers(obj) {
      let me = this;
      obj.forEach((item) => {
        //按照 异常个数 超标个数 预警个数这三个数的顺序进行显示
        item.error_number =
          item.YC > 0 ? item.YC : item.CB > 0 ? item.CB : item.YJ;
        item.error_number = item.error_number.toString();

        item.labelStyle =
          item.YC > 0
            ? this.labelStyleYc
            : item.CB > 0
            ? this.labelStyleCb
            : this.labelStyleYj;

        item.url = me.getMarkerImg(item);
        // console.log(item.url);
      });
      return obj;
    },

    /**
     * 获取 marker 的图片 url
     */
    getMarkerImg(item) {
      // console.log(item);
      let url = ``;
      switch (item.station_Type) {
        case 1:
        case "1":
          url = "fq";
          break;
        case 2:
        case "2":
          url = "fs";
          break;
        case 3:
        case "3":
          url = "yc";
          break;
        case 4:
        case "4":
          url = "vocs";
          break;
      }
      // 离线
      if (item.ConnectState == 0 || item.ConnectState == "0") {
        return `${url}-lx.png`;
      } else {
        // 异常图标
        if (item.YC != 0) {
          return `${url}-yc.png`;
        } else if (item.CB != 0) {
          // 超标图标
          return `${url}-cb.png`;
        } else if (item.YJ != 0) {
          // 预警图标
          return `${url}-yj.png`;
        } else {
          return `${url}.png`;
        }
      }
    },

    onClose() {
      this.visible = false;
    },

    onToggle() {
      this.visible = true;
    },

    //基站-表格点击事件
    onCustomRow(record) {
      // console.log(record);
      console.log(record.station_lat);
      console.log(record.station_long);
      this.center.lng = record.station_long;
      this.center.lat = record.station_lat;
    },

    //日数据分析报告-表格点击事件
    onCustomRow1(record) {
      // console.log(record);

      this.reportData = record;

      this.mapTableDialog = true;
    },

    onMapSetData(params) {
      console.log(params);
      this.params = params;
      this.getAjax();
    },

    // //关闭弹框
    // onDeleteModal(){
    //   this.mapTableDialog = false;
    // },

    /**
     * 地图内基站点击事件
     */
    onClickStation(item) {
      let me = this;
      console.log(item);
      this.baseData = me.markers[item];
      me.mapBaseDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
#map {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  .bm-view {
    width: 100%;
    height: 100%;
  }

  .img-btn {
    position: absolute;
    left: 0;
    top: calc(50% - 80px);
    width: 40px;
    height: 80px;
    background-image: url(/map/legend/legend-left.png);
    background-size: 100% 100%;
    transform: rotate(180deg);
    cursor: pointer;
    opacity: 1;
  }
}
</style>

<style lang="scss">
@import "./MapBase.scss";
</style>
