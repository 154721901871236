<template>
  <div id="CitySelect">
    <a-select
      style="width: 30%"
      v-model="provinceMC"
      @change="handleChangeSheng"
    >
      <a-select-option
        v-for="(item, index) in provincesData"
        :key="index"
        :value="item.key"
      >
        {{ item.title }}
      </a-select-option>
    </a-select>
    <a-select
      style="width: 30%"
      v-model="cityMC"
      @change="handleChangeshengShi"
    >
      <a-select-option
        v-for="(item, index) in citysData"
        :key="index"
        :value="item.key"
      >
        {{ item.title }}
      </a-select-option>
    </a-select>
    <a-select
      style="width: 30%"
      v-model="countyMC"
      @change="handleChangeshengXian"
    >
      <a-select-option
        v-for="(item, index) in countysData"
        :key="index"
        :value="item.key"
      >
        {{ item.title }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
import { http } from "@/utils/Http.js";

export default {
  data() {
    return {
      //省数据
      provinceMC: "",
      province: "",
      provincesData: [],

      //市数据
      cityMC: "",
      city: "",
      citysData: [],

      //县数据
      countyMC: "",
      county: "",
      countysData: [],

      //树结构数据
      Data: [],
    };
  },

  created() {
    //省份
    // this.BD_DB_SFInfo();

    this.HJJC_TreeListGet();
  },
  methods: {
    //省
    BD_DB_SFInfo() {
      let me = this;
      let params = {
        Module: "BD",
        DataWhere: "def=0",
      };
      http({
        url: "/data/q30/AutoTable_Select.aspx?TableName=BD_DB_SFInfo",
        data: params,
        success(obj) {
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            // me.province = [obj.Data[0].SFBH];
            me.province = obj.Data[0].SFBH;
            // console.log(me.province);
            //市份
            // me.BD_DB_SInfo(obj.Data[0].SFBH);
          }
          obj.Data.unshift({
            SFBH: "",
            SFMC: "全国",
          });
          me.provincesData = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    //市
    BD_DB_SInfo(SFBH, type) {
      let me = this;
      let params = {
        Module: "BD",
        DataWhere: `def=0 and SFBH='${SFBH}'`,
      };
      http({
        url: "/data/q30/AutoTable_Select.aspx?TableName=BD_DB_SInfo",
        data: params,
        success(obj) {
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            me.city = obj.Data[0].SBH;
            // console.log(me.city);
          }
          me.citysData = obj.Data;

          //县
          // me.BD_DB_XInfo(SFBH, obj.Data[0].SBH);
        },
        failure(obj) {},
        me,
      });
    },

    //县
    BD_DB_XInfo(SFBH, SBH) {
      let me = this;
      let params = {
        Module: "BD",
        DataWhere: `def=0 and SFBH = '${SFBH}' and  SBH = '${SBH}'`,
      };
      http({
        url: "/data/q30/AutoTable_Select.aspx?TableName=BD_DB_XInfo",
        data: params,
        success(obj) {
          console.log(obj);

          //县数据
          if (obj.Data && obj.Data.length > 0) {
            me.county = obj.Data[0].SBH;
            // console.log(me.county);
          }
          me.countysData = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    HJJC_TreeListGet() {
      var me = this;
      http({
        url: "/data/q30/HJJC_TreeListGet.aspx",
        data: {
          ckm: me.$store.state.loggedIn.CKM,
          MC: "",
        },
        success(obj) {
          console.log(obj);
          let objTree = JSON.parse(JSON.stringify(obj));

          //格式数据
          var Data = me.toTreeData(objTree.Data);
          me.Data = Data;
          me.setData(1);
        },
        failure(obj) {},
        me,
      });
    },

    //格式化树数据
    toTreeData(data) {
      var me = this;
      var pos = {};
      var tree = [];
      var i = 0;
      while (data.length != 0) {
        if (data[i].parentId == "0") {
          tree.push({
            key: data[i].id,
            title: data[i].name,
            slots: {
              treeData: data[i],
              icon: "folder",
            },
            children: [],
          });
          pos[data[i].id] = [tree.length - 1];
          data.splice(i, 1);
          i--;
        } else {
          var posArr = pos[data[i].parentId];
          if (posArr != undefined) {
            var obj = tree[posArr[0]];
            for (var j = 1; j < posArr.length; j++) {
              obj = obj.children[posArr[j]];
            }
            var isLeaf = false;
            var icons = "folder";
            if (data[i].Lx == me.TreeLX) {
              isLeaf = true;
              icons = "";
            }
            obj.children.push({
              key: data[i].id,
              title: data[i].name,
              isLeaf: isLeaf,
              slots: {
                treeData: data[i],
                icon: icons,
              },
              treeData: data[i],
              children: [],
            });
            pos[data[i].id] = posArr.concat([obj.children.length - 1]);
            data.splice(i, 1);
            i--;
          }
        }
        i++;
        if (i > data.length - 1) {
          i = 0;
        }
      }
      return tree;
    },

    //省
    handleChangeSheng(value) {
      // console.log(value);
      //省数据
      this.province = value;

      //市数据
      this.cityMC = "";
      //县数据
      this.countyMC = "";

      this.citysData = [];
      this.countysData = [];

      this.setData(2, value);

      this.$emit("select", value);
    },

    //市
    handleChangeshengShi(value) {
      //   console.log(value);
      //市数据
      this.city = value;
      //县数据
      this.countyMC = "";
      this.countysData = [];

      this.setData(3, value);

      // this.BD_DB_XInfo(this.province, value);

      this.$emit("select1", value);
    },

    //县
    handleChangeshengXian(value) {
      //   console.log(value);
      //县数据
      this.county = value;

      this.$emit("select2", value);
    },

    setData(type, id) {
      if (type == 1) {
        //省数据
        this.provincesData = this.Data[0].children;
        this.provincesData.unshift({
          key: "",
          title: "全国",
        });
      } else if (type == 2) {
        //市数据
        let data = this.provincesData;
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          if (data[i].key == id) {
            this.citysData = data[i].children;
            console.log(this.citysData);
          }
        }
      } else if (type == 3) {
        //县数据
        let data = this.citysData;
        for (var i = 0; i < data.length; i++) {
          if (data[i].key == id) {
            this.countysData = data[i].children;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#CitySelect {
  display: flex;
  justify-content: space-between;
}
</style>
