<template>
  <div id="mapBase">
    <div class="content">
      <div class="base-station">
        <h4>企业基站</h4>
        <div class="city-select">
          <city-select
            @select="selectSheng"
            @select1="selectShi"
            @select2="selectXian"
          ></city-select>
        </div>
        <div class="map-nav-public">
          <div class="title">基站类型</div>
        </div>
        <div style="margin-top: 16px; padding-left: 24px">
          <a-checkbox-group
            v-model="checkedList"
            :options="plainOptions"
            @change="onChange"
          />

          <!-- <a-radio-group
            v-model="station_type"
            :options="plainOptions"
            @change="onChange"
          /> -->
        </div>
        <div class="search-shell">
          <a-input-search
            v-model="MC"
            placeholder="请输入企业名称或基站名称"
            enter-button="搜索"
            @search="onSearch"
          />
        </div>
        <div class="table-shell">
          <a-table
            :columns="columns"
            :data-source="data"
            :loading="loading"
            bordered
            :customRow="customRow"
            :pagination="false"
            :row-key="
              (r, i) => {
                return i.toString();
              }
            "
          >
            <span slot="station_name" slot-scope="station_name">
              <!-- <span style="color: #1876ff">{{ station_name }}</span> -->
              <span>{{ station_name }}</span>
            </span>
          </a-table>
          <div style="display: flex; margin-top: 20px">
            <div style="flex: 1"></div>
            <a-pagination
              size="small"
              show-quick-jumper
              :page-size="pageSize"
              show-less-items
              v-model="current"
              :total="total"
              :show-total="(total, range) => `共 ${total} 条`"
              @change="onChangeGroup"
            />
            <div style="flex: 1"></div>
          </div>
        </div>
      </div>
      <div class="report-form">
        <div class="map-nav-public">
          <div class="title">日数据分析总报表（{{ all_count }}个基站）</div>
          <div style="flex: 1"></div>
          <a-date-picker
            :defaultValue="moment(new Date())"
            :format="dateFormat"
            style="width: 120px"
            @change="onChangeDate"
            size="small"
          />
        </div>
        <div class="table-shell" style="margin-top: 12px">
          <a-table
            :columns="columns1"
            :data-source="data1"
            :loading="loading1"
            bordered
            :pagination="false"
            :row-key="
              (r, i) => {
                return i.toString();
              }
            "
          >
            <span slot="FQ" slot-scope="FQ, record" @click="onFQClick(record)">
              <!-- <span v-if="record.type == 1">{{ FQ }}</span> -->
              <!-- <span v-else style="color: #1876ff">{{ FQ }}</span> -->
              <span>{{ FQ }}</span>
            </span>
            <span slot="FS" slot-scope="FS, record" @click="onFSClick(record)">
              <!-- <span v-if="record.type == 1">{{ FS }}</span>
              <span v-else style="color: #1876ff">{{ FS }}</span> -->
              <span>{{ FS }}</span>
            </span>
            <span slot="YC" slot-scope="YC, record" @click="onYCClick(record)">
              <!-- <span v-if="record.type == 1">{{ YC }}</span> -->
              <!-- <span v-else style="color: #1876ff">{{ YC }}</span> -->
              <span>{{ YC }}</span>
            </span>
            <span slot="VO" slot-scope="VO, record" @click="onVOClick(record)">
              <!-- <span v-if="record.type == 1">{{ VO }}</span> -->
              <!-- <span v-else style="color: #1876ff">{{ VO }}</span> -->
              <span>{{ VO }}</span>
            </span>
            <span slot="HJ" slot-scope="HJ, record">
              <!-- <span v-if="record.type == 1">{{ HJ }}</span> -->
              <!-- <span v-else style="color: #1876ff">{{ HJ }}</span> -->
              <span>{{ HJ }}</span>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <div class="img-btn" @click="onClick"></div>
  </div>
</template>
<script>
import CitySelect from "./CitySelect.vue";
import { http } from "@/utils/Http.js";
import dayjs from "dayjs";
import moment from "moment";

let columns = [
  {
    title: "序号",
    width: 60,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "企业名称",
    align: "center",
    dataIndex: "EnterpriseMC",
    width: 200,
    ellipsis: true,
  },
  {
    title: "基站名称",
    align: "center",
    dataIndex: "station_name",
    ellipsis: true,
    scopedSlots: { customRender: "station_name" },
  },
  {
    title: "基站类型",
    align: "center",
    dataIndex: "station_Type",
    ellipsis: true,
    customRender: (text, record, index) => {
      // 1废气 // 2废水 // 3扬尘 // 4VOCS
      // console.log(text);
      let name = "";

      if (text == 1) {
        name = "废气";
      } else if (text == 2) {
        name = "废水";
      } else if (text == 3) {
        name = "扬尘";
      } else if (text == 4) {
        name = "VOCS";
      }
      return name;
    },
  },
];

let columns1 = [
  // {
  //   title: "序号",
  //   width: 60,
  //   align: "center",
  //   customRender: (text, record, index) => {
  //     return index + 1;
  //   },
  // },
  // {
  //   title: "类别",
  //   align: "center",
  //   dataIndex: "LB",
  //   width: 180,
  //   ellipsis: true,
  // },
  // {
  //   title: "废气数量",
  //   align: "center",
  //   dataIndex: "FQ_count",
  //   ellipsis: true,
  //   scopedSlots: { customRender: "FQ_count" },
  // },
  // {
  //   title: "废水数量",
  //   align: "center",
  //   dataIndex: "FS_count",
  //   ellipsis: true,
  //   scopedSlots: { customRender: "FS_count" },
  // },
  // {
  //   title: "合计",
  //   align: "center",
  //   dataIndex: "all_count",
  //   ellipsis: true,
  //   scopedSlots: { customRender: "all_count" },
  // },

  {
    title: "序号",
    width: 40,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "类别",
    dataIndex: "type",
    align: "center",
    width: 120,
    customRender: (text, record, index) => {
      let name = "";

      // 1正常
      // 2、超标
      // 3、异常
      // 4、缺失
      // 5、停运

      if (text == 1) {
        name = "正常基站";
      } else if (text == 2) {
        name = "数据超标基站";
      } else if (text == 3) {
        name = "数据异常基站";
      } else if (text == 4) {
        name = "数据缺失基站";
      } else if (text == 5) {
        name = "停运基站";
      }
      return name;
    },
  },
  {
    title: "废气数量",
    dataIndex: "FQ",
    scopedSlots: { customRender: "FQ" },
    align: "center",
  },
  {
    title: "废水数量",
    dataIndex: "FS",
    scopedSlots: { customRender: "FS" },
    align: "center",
  },
  {
    title: "扬尘",
    dataIndex: "YC",
    scopedSlots: { customRender: "YC" },
    align: "center",
  },
  {
    title: "VOCs",
    dataIndex: "VO",
    scopedSlots: { customRender: "VO" },
    align: "center",
  },
  {
    title: "合计",
    dataIndex: "HJ",
    scopedSlots: { customRender: "HJ" },
    align: "center",
  },
];

export default {
  components: {
    CitySelect,
  },
  data() {
    return {
      moment,

      //省数据
      province: "",

      //市数据
      city: "",

      //县数据
      county: "",

      area_num: "",

      //基站类型编号
      checkedList: [],
      plainOptions: [],

      //基站或者企业名称
      MC: "",

      //基站类型
      station_type: "",

      //分页
      current: 1,
      pageSize: 7,
      total: 0,

      //日期
      dateFormat: "YYYY-MM-DD",

      //第一个表格数据
      data: [],
      columns,
      loading: false,

      // 日数据分析总报表
      today: dayjs(new Date()).format("YYYY-MM-DD"),
      loading1: false,
      data1: [],
      columns1,
      all_count: 0, //基站总数
    };
  },
  created() {
    this.SYS_DB_Dictionary();

    this.getAjax();
    this.getAjax1();
  },
  methods: {
    onClick() {
      this.$emit("close");
    },

    //省
    selectSheng(value) {
      this.province = value;
      this.area_num = value;
    },

    //市
    selectShi(value) {
      this.city = value;
      this.area_num = value;
    },

    //县
    selectXian(value) {
      this.county = value;
      this.area_num = value;
    },

    //选择基站类型
    onChange(checkedValues) {
      // console.log("checked = ", checkedValues);
      // console.log(this.checkedList);
    },

    //搜索
    onSearch(value) {
      this.current = 1;

      let station_type = "";
      if (this.checkedList && this.checkedList.length > 0) {
        station_type = this.checkedList.join(",");
      }
      let params = {
        MC: this.MC,
        area_num: this.area_num,
        station_type: station_type,
      };
      this.$emit("mapSetData", params);
      this.getAjax();
    },

    //分页
    onChangeGroup(current, pageSize) {
      let me = this;
      me.current = current || 1;
      me.getAjax();
    },

    // 日数据分析总报表
    //选择日期
    onChangeDate(date, dateString) {
      console.log(date, dateString);
      this.today = dateString;
      this.getAjax1();
    },

    // 基站表格点击事件
    customRow(record, index) {
      var me = this;
      return {
        on: {
          // 鼠标单击行
          click: (event) => {
            // console.log(record);
            me.$emit("shellCustomRow", record);
          },
        },
      };
    },

    // // 日数据分析报告
    // :customRow="customRow1"
    // customRow1(record, index) {
    //   var me = this;
    //   return {
    //     on: {
    //       // 鼠标单击行
    //       click: (event) => {
    //         console.log(event);
    //         return;
    //         if (record.LBBH != 0) {
    //           me.$emit("shellCustomRow1", record, me.today);
    //         }
    //       },
    //     },
    //   };
    // },

    //基站类型
    SYS_DB_Dictionary() {
      var me = this;
      http({
        url: "/data/q30/AutoTable_Select.aspx?TableName=SYS_DB_Dictionary",
        data: {
          Module: "BD",
          DataWhere: "LX=8",
        },
        success(obj) {
          console.log(obj);

          for (var i = 0; i < obj.Data.length; i++) {
            me.plainOptions.push({
              label: obj.Data[i].FieldMC,
              value: obj.Data[i].FieldBH,
            });
          }
        },
        failure(obj) {},
        me,
      });
    },

    /**
     * 请求接口
     */
    getAjax() {
      let me = this;
      let station_type = "";
      if (this.checkedList && this.checkedList.length > 0) {
        station_type = this.checkedList.join(",");
      }
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        MC: this.MC,
        area_num: this.area_num,
        station_type: station_type,
        station_time: this.today,
        PAGESIZE: me.pageSize,
        PAGENUMBER: me.current,
      };
      console.log(params);
      me.loading = true;
      http({
        url: "/data/q30/HJJC_MAP_LIST.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          me.loading = false;

          me.data = obj.Data1;
          if (obj.Data2 && obj.Data2.length > 0) {
            me.total = obj.Data2[0].ROWSCOUNT;
          }
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    /**
     * 请求接口
     */
    getAjax1() {
      let me = this;
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        TOTALDAY: this.today,
      };
      console.log(params);
      me.loading1 = true;
      http({
        url: "/data/q30/HJJC_DAYTOTAL.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          me.loading1 = false;

          me.data1 = obj.Data;

          if (obj.Data1 && obj.Data1.length > 0) {
            me.all_count = obj.Data1[0].JZS;
          }
        },
        failure(obj) {
          me.loading1 = false;
        },
        me,
      });
    },

    //废气列点击
    onFQClick(record) {
      this.transmitData(record, 1);
    },

    //废水列点击
    onFSClick(record) {
      this.transmitData(record, 2);
    },

    //扬尘列点击
    onYCClick(record) {
      this.transmitData(record, 3);
    },

    //VO列点击
    onVOClick(record) {
      this.transmitData(record, 4);
    },

    transmitData(record, LX) {
      let me = this;

      if (record.type != 1) {
        var obj = {
          type: record.type, //报警类型
          TOTALDAY: me.today, //统计时间
          LX: LX, //基站类型
        };
        me.$emit("shellCustomRow1", obj);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#mapBase {
  width: 569px;
  height: 100%;
  padding-right: 40px;
  position: relative;

  .content {
    height: 100%;
    padding: 14px 29px;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;

    .base-station {
      height: 67.4%;
      margin-bottom: 12px;
      background-color: #fff;
      box-shadow: 5px 10px 20px rgba(207, 207, 207, 0.31);
      opacity: 1;
      border-radius: 10px;
      padding: 0 24px;
      overflow: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-bottom: 10px;

      h4 {
        padding: 6px 0 15px 0;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
        text-align: center;
        margin: 0;
      }

      .city-select {
        margin-bottom: 10px;
      }

      .search-shell {
        margin: 18px 0 10px 0;
        height: 32px;

        &::v-deep .ant-input-group-addon {
          .ant-btn {
            width: 93px;
            background: #1876ff;
            border-radius: 0px 5px 5px 0px;
          }
        }
      }

      &::v-deep .ant-checkbox-group-item {
        margin-right: 30px;
      }

      &::v-deep .ant-radio-wrapper {
        margin-right: 30px;
      }
    }

    .base-station::-webkit-scrollbar {
      width: 0;
      background-color: #d9d9d9;
    }

    .base-station::-webkit-scrollbar-thumb {
      height: 0;
      width: 0;
      background-color: #d9d9d9;
    }

    .report-form {
      flex: 1;
      overflow: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 200px;
      padding: 0 24px 10px;
      background-color: #fff;
      box-shadow: 5px 10px 20px rgba(207, 207, 207, 0.31);
      opacity: 1;
      border-radius: 10px;
    }

    .report-form::-webkit-scrollbar {
      width: 0;
      background-color: #d9d9d9;
    }

    .report-form::-webkit-scrollbar-thumb {
      height: 0;
      width: 0;
      background-color: #d9d9d9;
    }
  }

  .table-shell {
    // height: 3.94rem;

    &::v-deep .ant-table-wrapper {
      table {
        border: 0;

        .ant-table-thead {
          tr {
            border: 0;
            th {
              border: 0;
              font-size: 12px;
              // padding: 7px 5px;
              padding: 0.08rem 0.05rem;
            }
          }
        }
        tr {
          border: 0;
          td {
            border: 0;
            // padding: 7px 5px;
            padding: 0.08rem 0.05rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  .map-nav-public {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    padding-top: 6px;
  }

  .img-btn {
    position: absolute;
    right: 0;
    top: calc(50% - 40px);
    width: 40px;
    height: 80px;
    background-image: url(/map/legend/legend-right.png);
    background-size: 100% 100%;
    transform: rotate(180deg);
    cursor: pointer;
    opacity: 1;
  }
}
</style>
