<template>
  <div id="legend">
    <!-- 隐藏 -->
    <div class="legend-hidden" v-show="isLegend">
      <div class="legend-left" @click="legendChange">
        <!-- <a-icon type="left" /> -->
      </div>
    </div>
    <!-- 显示 -->
    <div class="legend-show" v-show="!isLegend">
      <div class="legend-left" @click="legendChange">
        <!-- <a-icon type="right" /> -->
      </div>
      <div class="legend-center">
        <h4>状态</h4>
        <div
          class="map-legend-item"
          v-for="(item, index) in legendCenter"
          :key="index"
        >
          <img v-if="item.img" class="img" :src="item.img" :alt="item.title" />
          <div class="fs14">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="legend-right">
        <div class="legend-title">图例</div>
        <div class="legend-text">基站类型</div>
        <div class="map-legend">
          <div
            class="map-legend-item"
            v-for="(item, index) in legendRight"
            :key="index"
          >
            <img
              v-if="item.img"
              class="img"
              :src="item.img"
              :alt="item.title"
            />
            <div>
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="legend-text">聚合</div>
        <div class="map-legend">
          <div
            class="map-legend-item"
            v-for="(item, index) in legendRight1"
            :key="index"
          >
            <img
              v-if="item.img"
              class="img"
              :src="item.img"
              :alt="item.title"
            />
            <div>
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 右下方 legend 展示区域
      isLegend: false, // 右下角 legend 是否显示。
      legendCenter: [
        {
          color: "#0080FF",
          title: "在线",
          img: "/tinified/zt/206.png",
        },
        {
          color: "#808080",
          title: "离线",
          img: "/tinified/zt/207.png",
        },
        {
          color: "#800080",
          title: "异常",
          img: "/tinified/zt/208.png",
        },
        {
          color: "#FF0000",
          title: "超标",
          img: "/tinified/zt/209.png",
        },
        {
          color: "#FF8000",
          title: "预警",
          img: "/tinified/zt/210.png",
        },
      ],
      legendRight: [
        {
          title: "废气",
          img: "/map/fq.png",
        },
        {
          title: "废水",
          img: "/map/fs.png",
        },
        {
          title: "扬尘",
          img: "/map/yc.png",
        },
        {
          title: "VOCS",
          img: "/map/vocs.png",
        },
      ],
      legendRight1: [
        {
          title: "基站聚合",
          img: "/map/jh.png",
        },
      ],
    };
  },
  methods: {
    /**
     * 地图左下角 legend 的隐藏与显示
     */
    legendChange() {
      let me = this;
      me.isLegend = !me.isLegend;
    },
  },
};
</script>
<style lang="scss" scoped>
#legend {
  .legend-hidden {
    display: flex;
    width: 40px;
    height: 165px;
    .legend-left {
      cursor: pointer;
      height: 80px;
      width: 40px;
      background-image: url(/map/legend/legend-left.png);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .legend-show {
    padding-right: 20px;
    display: flex;
    width: 280px;
    height: 340px;

    .legend-right {
      height: 100%;
      width: 120px;
      padding: 0 6px 0 10px;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border-radius: 10px 10px 0px 0px;

      .legend-title {
        padding: 10px 0;
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
        border-bottom: 1px solid #f1f1f1;
      }

      .legend-text {
        margin-bottom: 10px;
        margin-top: 6px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 19px;
        color: #000000;
      }

      .map-legend {
        .map-legend-item {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          color: #000000;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;

          img {
            margin-right: 8px;
          }
        }

        .map-legend-item:last-child {
          margin-bottom: 10px;
        }
      }
    }

    .legend-center {
      height: 286px;
      width: 124px;
      padding-left: 10px;
      background-color: #ffffff;
      margin-top: 55px;
      border-radius: 10px 0px 0px 0px;
      border-right: 1px solid #f1f1f1;

      h4 {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 19px;
        color: #000000;
        padding: 18px 0 0 8px;
      }

      .map-legend-item {
        display: flex;
        align-items: center;
        margin-bottom: 3px;

        img {
          width: 44px;
          height: 44px;
        }
      }
    }
    .legend-left {
      margin-top: 163px;
      cursor: pointer;
      height: 80px;
      width: 40px;
      color: #09c8ff;
      background-image: url(/map/legend/legend-right.png);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
