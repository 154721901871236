<template>
  <div id="MapDetails">
    <div class="title">
      <div style="width: 18px"></div>
      <p>{{ title }}</p>
    </div>
    <div class="essential">
      <div class="headline-news">基本信息</div>
      <div class="table-copy">
        <div class="list color1876FF">
          <div class="one" style="flex: 2">企业名称</div>
          <div class="one" style="flex: 2">基站名称</div>
          <div class="one">基站类型</div>
          <div class="one">状态</div>
        </div>
        <div class="list fontWeight">
          <div class="one" style="flex: 2">{{ basein.EnterpriseMC }}</div>
          <div class="one" style="flex: 2">{{ basein.station_name }}</div>
          <div class="one">{{ basein.station_type }}</div>
          <div class="one" v-if="basein.ConnectState == 0">下线</div>
          <div class="one" v-else>在线</div>
        </div>
      </div>
    </div>
    <div class="real-time">
      <div class="headline-news">基站实时报警数据</div>
      <div class="real-time-list">
        <div class="call-the-police">
          <div class="number">{{ realData.Abnormal_num }}<span>次</span></div>
          <div class="text">异常</div>
          <img :src="deleteImg2" alt="" />
        </div>
        <div class="call-the-police">
          <div class="number">{{ realData.Overproof_num }}<span>次</span></div>
          <div class="text">超标</div>
          <img :src="deleteImg3" alt="" />
        </div>
        <div class="call-the-police">
          <div class="number">{{ realData.Early_num }}<span>次</span></div>
          <div class="text">预警</div>
          <img :src="deleteImg4" alt="" />
        </div>
      </div>
    </div>
    <div class="total-query">
      <div class="headline-news">总量查询</div>
      <div class="total-query-table">
        <div class="table-copy">
          <div class="list fontSize fontWeight">
            <div class="one">基站名称</div>
            <div class="one">基站类型</div>
            <div class="one">综合排放量 (Kg)</div>
            <div class="one">使用率</div>
            <div class="one">税收合计 (元)</div>
          </div>
          <div class="list color666">
            <div class="one">{{ totalData.station_name }}</div>
            <div class="one">{{ totalData.station_type }}</div>
            <div class="one">{{ totalData.dischargeTotal }}</div>
            <div class="one">{{ totalData.useTotal }}</div>
            <div class="one">{{ totalData.TaxAmountTotal }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="outage-record">
      <div class="headline-news">停运记录</div>
      <div class="outage-record-table">
        <div class="table-shell">
          <a-table
            class="full-table"
            :loading="loading"
            :columns="columns"
            bordered
            size="small"
            :row-key="
              (r, i) => {
                return i.toString();
              }
            "
            :data-source="data"
            :pagination="false"
          >
          </a-table>
        </div>
        <div style="display: flex; padding: 10px 20px">
          <div style="flex: 1"></div>
          <a-pagination
            :page-size="pageSize"
            v-model="current"
            :total="total"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { http } from "@/utils/Http.js";
const columns = [
  {
    title: "添加时间",
    align: "center",
    dataIndex: "TJSJ",
    ellipsis: true,
  },
  {
    title: "停运原因",
    align: "center",
    dataIndex: "TYYY",
    ellipsis: true,
  },
  {
    title: "停运时段",
    align: "center",
    dataIndex: "TYSD",
    ellipsis: true,
  },
];
export default {
  props: {
    baseData: Object,
  },
  components: {},
  data() {
    return {
      title: "基站详情",

      // deleteImg1: require("../../assets/img/pics/042.png"),

      deleteImg2: require("../../assets/img/pics/043.png"),
      deleteImg3: require("../../assets/img/pics/044.png"),
      deleteImg4: require("../../assets/img/pics/045.png"),

      columns,
      data: [],
      loading: false,

      //分页
      current: 1,
      pageSize: 3,
      total: 0,

      //基本信息
      basein: {},

      //基站信息
      realData: {},

      //总量信息
      totalData: {},
    };
  },
  created() {
    this.HJJC_MAP_Station_more(this.baseData);
  },
  watch: {
    baseData(value) {
      console.log(value);
      this.current = 1;
      this.data = [];
      this.HJJC_MAP_Station_more(value);
    },
  },
  methods: {
    //分页
    onChange(page, pageSize) {
      this.current = page;
      this.HJJC_MAP_Station_more(this.baseData);
    },

    HJJC_MAP_Station_more(value) {
      let me = this;
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        station_bh: value.station_bh,
        PAGESIZE: this.pageSize,
        PAGENUMBER: this.current,
      };
      http({
        url: "/data/q30/HJJC_MAP_Station_more.aspx",
        data: params,
        success(obj) {
          console.log(obj);

          if (obj.Data && obj.Data.length > 0) {
            me.basein = obj.Data[0];
          }
          if (obj.Data1 && obj.Data1.length > 0) {
            me.realData = obj.Data1[0];
          }
          if (obj.Data2 && obj.Data2.length > 0) {
            me.totalData = obj.Data2[0];
          }
          me.data = obj.Data3;

          if (obj.Data4 && obj.Data4.length > 0) {
            me.total = obj.Data4[0].ROWSCOUNT;
          }
        },
        failure(obj) {},
        me,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#MapDetails {
  height: 8.4rem;
  overflow: hidden;
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  .title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    p {
      text-align: center;
      flex: 1;
      margin: 0;
    }

    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .essential {
    padding-bottom: 10px;
  }

  .real-time {
    padding-bottom: 20px;

    .real-time-list {
      display: flex;
      align-items: center;
      padding-top: 18px;
      display: flex;
      align-items: center;

      .call-the-police {
        width: 134px;
        height: 89px;
        background: #ffffff;
        box-shadow: 5px 5px 20px #eeeeee;
        border-radius: 10px;
        position: relative;
        text-align: center;
        margin-right: 38px;
        padding-bottom: 20px;

        .number {
          font-size: 32px;
          font-family: PingFang SC;
          line-height: 38px;
          color: #333333;
          padding: 10px 0 4px 0;

          span {
            font-size: 16px;
          }
        }

        .text {
          font-size: 16px;
          font-family: PingFang SC;
          line-height: 22px;
          color: #666666;
        }

        img {
          position: absolute;
          width: 51px;
          height: 51px;
          right: 0;
          top: 0;
        }
      }
    }
  }

  .total-query {
    padding-bottom: 20px;

    .total-query-table {
      padding-top: 18px;
      height: 120px;
      background: #ffffff;
      box-shadow: 5px 10px 20px rgba(207, 207, 207, 0.16);
      border-radius: 10px;
    }
  }

  .outage-record {
    .outage-record-table {
      padding-top: 18px;

      .table-shell {
        // height: 3.94rem;
        &::v-deep .ant-table-wrapper {
          .ant-table-placeholder {
            margin-bottom: 5px;
            .ant-empty {
              margin: 5px 0 0 0;
            }
          }

          .ant-table-content {
            border: 0;
          }
          .ant-table {
            border: 0;
          }

          table {
            border: 0;

            .ant-table-thead {
              tr {
                border: 0;
                th {
                  border: 0;
                  font-size: 12px;
                  padding: 10px 5px;
                }
              }
            }
            tr {
              border: 0;
              td {
                border: 0;
                padding: 9px 5px;
              }
            }
          }
        }
      }
    }
  }

  .table-copy {
    padding-top: 20px;
    .list {
      text-align: center;
      display: flex;
      align-items: center;
      padding: 8px 4px;
      font-size: 14px;
      color: #333333;

      .one {
        flex: 1;
      }
    }

    .color1876FF {
      color: #1876ff;
    }

    .fontWeight {
      font-weight: 700;
    }

    .color666 {
      color: #666;
    }

    .fontSize {
      font-size: 12px;
    }
  }

  .headline-news {
    padding-top: 20px;
    border-top: 1px solid #f1f1f1;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #1876ff;
  }
}

#MapDetails::-webkit-scrollbar {
  width: 0;
}

#MapDetails::-webkit-scrollbar-thumb {
  width: 0;
}
</style>
